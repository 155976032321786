import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Policy } from './components/policy';
import { Terms } from './components/terms';
import { Navigation } from './components/navigation';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/pages/policy",
    element: <Policy />,
  },
  {
    path: "/pages/terms",
    element: <Terms />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <Navigation data={window.env.Navigation} />
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();