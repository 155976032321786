import React from "react";

export const Uninstall = (props) => {
  return (
    <div id="uninstall" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Uninstall</h2>
        </div>
        <div className="row">
          To uninstall Chrome extension please follow the instructions &nbsp;
          <a
            id="uninstall_link"
            href="https://support.google.com/chrome_webstore/answer/2664769"
            target="_blank"
            rel="noopener"
          >
            here
          </a>
          . We hope to see you again. Please share with us your experience.
        </div>
      </div>
    </div>
  );
};
