import React from "react";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Uninstall } from "./components/uninstall";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <div>
      <Header data={window.env.Header} />
      <Features data={window.env.Features} />
      <About data={window.env.About} />
      <Gallery data={window.env.Gallery} />
      <Uninstall />
      <Contact data={window.env.Contact} />
    </div>
  );
};

export default App;
