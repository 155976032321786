import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
              </div>
              <div className="contact-item">
                <h3>Please share with us your feedback.</h3>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i class="fa fa-map-pin"></i>Name
                  </span>
                  {props.data ? props.data.name : "loading"}
                </p>
              </div>
              {props.data && props.data.address && (
                <div className="contact-item">
                  <p>
                    <span>
                      <i class="fa fa-map-marker"></i>Address
                    </span>
                    {props.data.address}
                  </p>
                </div>
              )}
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i>Email
                  </span>

                  {props.data ? (
                    <a href={`mailto:${props.data.email}`}>
                      {props.data.email}
                    </a>
                  ) : (
                    "loading"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            Copyright &copy; {window.env.Host} {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
};
