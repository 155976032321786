import React from "react";

export const Policy = (props) => {
  return (
    <div className="privacy">
      <div className="container">
        <div className="row">
          <h2>Privacy Policy</h2>
          <h3>Collecting and Using Your Personal Data</h3>
          <h3>Security of Your Personal Data</h3>
        </div>
        <div className="row">
          This Privacy Policy is meant to help you understand what information
          we collect, why we collect it, and how you can update, manage, export,
          and tell You about Your privacy rights and how the law protects You.{" "}
          <br />
          Please read this Privacy Policy carefully, before using our Software &
          Services so you are aware, acknowledge and agree to the terms and
          processing activities described in it.
          <br />
          While We strive to use commercially acceptable means to protect Your
          Personal Data, We cannot guarantee its absolute security.
        </div>
        <div className="row">
          <h3>Personal Data</h3>
        </div>
        <div className="row">
          While using Our Service, We may ask You for personally identifying
          information that can be used to contact or identify You. Usage Data is
          one type of personally identifiable information that may be collected.
        </div>
        <div className="row">
          <h3>Usage Data</h3>
        </div>
        <div className="row">
          Usage Data has collected automatically when using the Service. Usage
          Data may include information such as Your Device's Internet Protocol
          address (e.g. IP address), browser type, browser version, the pages of
          our Service that You visit, the time and date of Your visit, the time
          spent on those pages, unique device identifiers and other diagnostic
          data. When You access the Service by or through a mobile device, We
          may collect certain information automatically, including, but not
          limited to, the type of mobile device You use, Your mobile device
          unique ID, the IP address of Your mobile device, Your mobile operating
          system, the type of mobile Internet browser You use, unique device
          identifiers and other diagnostic data. We may also collect information
          that Your browser sends whenever You visit our Service or when You
          access the Service by or through a mobile device.
        </div>
        <div className="row">
          <h3>Information Collected while Using the Application</h3>
        </div>
        <div className="row">
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission: Information
          regarding your location. We use this information to provide features
          of Our Service, to improve and customize Our Service. The information
          may be uploaded to the Company's servers and/or a Service Provider's
          server or it may be simply stored on Your device. You can enable or
          disable access to this information at any time, through Your Device
          settings.
        </div>
        <div className="row">
          <h3>Use of Your Personal Data</h3>
        </div>
        <div className="row">
          The Company may use Personal Data for the following purposes: To
          provide and maintain our Service, including to monitor the usage of
          our Service. To manage Your Account: to manage Your registration as a
          user of the Service. The Personal Data You provide can give You access
          to different functionalities of the Service that are available to You
          as a registered user; For the performance of a contract: the
          development, compliance, and undertaking of the purchase contract for
          the products, items, or services You have purchased or of any other
          contract with Us through the Service. To contact You: To contact You
          by email, telephone calls, SMS, or other equivalent forms of
          electronic communication, such as a mobile application's push
          notifications regarding updates or informative communications related
          to the functionalities, products, or contracted services, including
          the security updates, when necessary or reasonable for their
          implementation. To provide You with news, special offers, and general
          information about other goods, services, and events that we offer that
          are similar to those that you have already purchased or enquired about
          unless You have opted not to receive such information. To manage Your
          requests: To attend and manage Your requests to Us. For business
          transfers: We may use Your information to evaluate or conduct a
          merger, divestiture, restructuring, reorganization, dissolution, or
          another sale or transfer of some or all of Our assets, whether as a
          going concern or as part of bankruptcy, liquidation, or similar
          proceeding, in which Personal Data held by Us about our Service users
          is among the assets transferred. For other purposes: We may use Your
          information for other purposes, such as data analysis, identifying
          usage trends, determining the effectiveness of our promotional
          campaigns, and evaluating and improving our Service, products,
          services, marketing, and experience. We may share Your personal
          information in the following situations:
        </div>
        <div className="row">
          <ul>
            <li>
              With Service Providers: We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, to contact You.
            </li>
            <li>
              For business transfers: We may share or transfer Your personal
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of Our business to another company.
            </li>
            <li>
              With Affiliates: We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              With business partners: We may share Your information with Our
              business partners to offer You certain products, services or
              promotions.
            </li>
            <li>
              With other users: when You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside.
            </li>
            <li>
              With Your consent: We may disclose Your personal information for
              any other purpose with Your consent.
            </li>
          </ul>
        </div>
        <div className="row">
          <h3>Transfer of Your Personal Data</h3>
        </div>
        <div className="row">
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country, or other governmental jurisdiction
          where the data protection laws may differ from those from Your
          jurisdiction. The Company will take all reasonable steps to ensure
          that Your data is treated securely and in accordance with this Privacy
          Policy, and no transfer of Your Personal Data to an organization or
          country will take place unless adequate controls, including the
          security of Your data and other personal information, are in place.
        </div>
        <div className="row">
          <h2>Retention of Your Personal Data</h2>
        </div>
        <div className="row">
          In accordance with this Privacy Policy, the Company will only keep
          Your Personal Data for as long as necessary. The use of Your Personal
          Data is strictly limited to complying with our legal responsibilities
          (for example, if we have to keep your data in order to comply with
          applicable laws), resolving disputes, and enforcing our legal
          agreements. Usage Data will also be retained by the Company for
          internal analysis. Usage Data is typically stored for a shorter length
          of time, unless it is needed to increase the security or functioning
          of Our Service, or We are legally required to retain this data for a
          longer period of time.
        </div>
        <div className="row">
          <h2>Disclosure of Your Personal Data</h2>
          <h3>Business Transactions</h3>
        </div>
        <div className="row">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </div>
        <div className="row">
          <h3>Law enforcement</h3>
        </div>
        <div className="row">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </div>
        <div className="row">
          <h3>Other legal requirements</h3>
        </div>
        <div className="row">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
        </div>
        <div className="row">
          <h2>Children's Privacy</h2>
        </div>
        <div className="row">
          Our Service is not intended for children under the age of 13. We do
          not intentionally collect personally identifying information from
          children under the age of 13. Please contact us if You are a parent or
          guardian and You are aware that Your kid has supplied Us with Personal
          Data. If We discover that We have acquired Personal Data from anyone
          under the age of 13 without parental consent, We will take measures to
          delete such information from Our systems. If We need to rely on
          consent as a legal basis for processing Your information and Your
          nation requires parental consent, We may need Your parent's permission
          before collecting and using that information. Links to Other Websites
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third-party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit. We have no control over and assume no
          responsibility for the content, privacy policies, or practices of any
          third-party sites or services.
        </div>
        <div className="row">
          <h2>Changes to this Privacy Policy</h2>
        </div>
        <div className="row">
          We reserve the right to make changes to Our Privacy Policy at any
          time. Any modifications will be communicated to You by publishing the
          updated Privacy Policy on this page. Prior to the modification being
          effective, We will notify You by email and/or a conspicuous notice on
          Our Service, and we will update the "Last Updated" date at the top of
          this Privacy Policy. It is recommended that you examine this Privacy
          Policy on a regular basis for any updates. Changes to this Privacy
          Statement become effective when they are posted on this page. If you
          have any questions or concerns with respect to Twobi Tab Browser
          Extension, you may contact us by email or at the following address:
          <i>
            <br />
            <br />
            {window.env.Contact.name}
            <br />
            {window.env.Contact.address ? (
              <>
                {window.env.Contact.address}
                <br />
              </>
            ) : null}
            <a href={`mailto:${window.env.Contact.email}`}>
              {window.env.Contact.email}
            </a>
            <br />
          </i>
        </div>
      </div>
    </div>
  );
};
